.inputsContainer {
  margin: 10px 40px;
  display: flex;
}

.inputsContainer > div {
  margin: 5px;  
}


@media only screen and (max-width: 600px) {
  .inputsContainer {
    margin: 5px ;
    display: block;
  }
  
  .inputsContainer > div {
    margin: 3px;  
  }
}